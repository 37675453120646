import { db } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export const api = {
  // Get user data (progress and favorites)
  async getUserData(username) {
    try {
      const userRef = doc(db, 'users', username);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        // Return existing data instead of creating new
        return userSnap.data();
      } else {
        // Only create new user data if it doesn't exist at all
        const defaultData = {
          id: username,
          videos: [],
          favorites: []
        };
        await setDoc(userRef, defaultData, { merge: true }); // Added merge option
        return defaultData;
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      return {
        id: username,
        videos: [],
        favorites: []
      };
    }
  },

  // Update user data - modified to merge data
  async updateUserData(username, userData) {
    try {
      const userRef = doc(db, 'users', username);
      await setDoc(userRef, {
        id: username,
        videos: userData.videos || [],
        favorites: userData.favorites || []
      }, { merge: true }); // Added merge option
      
      return userData;
    } catch (error) {
      console.error('Error updating user data:', error);
      throw error;
    }
  }
};