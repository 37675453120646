import React from 'react';

const FilloutQuiz = ({ quizUrl }) => {
  return (
    <div style={{ height: '100vh', width: '100%', overflow: 'hidden' }}>
      <iframe
        src={quizUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Fillout Quiz"
      />
    </div>
  );
};

export default FilloutQuiz;