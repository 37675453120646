import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD2z0Ywkr__z-JLR1-2EwXvp-3HkNHCnEA",
  authDomain: "forex-database-858d1.firebaseapp.com",
  projectId: "forex-database-858d1",
  storageBucket: "forex-database-858d1.appspot.com",
  messagingSenderId: "54756323859",
  appId: "1:54756323859:web:30c5a62d4659df516005e6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);