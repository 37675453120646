export const sidebarData = [
  {
    title: "Road Map Guide",
    children: [
      { title: "TTF Road Map Guide", content: { type: 'video', id: 'ttf-road-map', videoUrl: 'https://drive.google.com/file/d/1K9NyxAxVvFKMEBhIFaJz1h9pcDtxHOf_/preview', additionalContent: [{
        type: 'button',
        text: 'TTF Road Map Guide',
        link: 'https://docs.google.com/spreadsheets/d/1N3qF2_QJ-cws3JHeU1WNKi2G3WDhGJw6WLTQ54YLtUk/edit?gid=49985015#gid=49985015'
      }] } }
    ]
  },
  {
    title: "Starter Course",
    children: [
      { 
        title: "Step 1",
        children: [
          { title: "Beginner Forex Education", content: { type: 'video', id: 'beginner-forex-education', videoUrl: 'https://drive.google.com/file/d/1fTdodGDS_BlFMYD1D0vWbSHoWun7P4Hz/view?usp=drivesdk' } },
          { title: "TradingView Tutorial", content: { type: 'video', id: 'tradingview-tutorial', videoUrl: 'https://drive.google.com/file/d/1VCSplISZLjHV4iLCHqnoADPvJnGjD6Mw/view?usp=drivesdk' } },
          { title: "Arty's Broker of Choice", content: { type: 'video', id: 'artys-broker-choice', videoUrl: 'https://drive.google.com/file/d/1nVyyS8ssp11PC90p87HdQV4VBnT4rCKA/view?usp=drivesdk' } },
          {
            title: "Step 1 Quiz",
            content: {
              type: 'quiz',
              id: 'step1-quiz',
              quizUrl: 'https://forms.fillout.com/t/f9poZ7EWRwus'
            }
          },
        ]
      },
      { 
        title: "Step 2",
        children: [
          { title: "Intermediate Trading Education", content: { type: 'video', id: 'intermediate-trading-education', videoUrl: 'https://drive.google.com/file/d/1Hs6oJ4lN87xepH7mHTxl7qBbyWpvIS_R/view?usp=drivesdk' } },
          { title: "Step 2 Quiz", content: { type: 'quiz', id: 'step2-quiz', quizUrl:  'https://forms.fillout.com/t/8ewqJcXfiXus' } },
        ]
      },
      { 
        title: "Step 3",
        children: [
          { title: "Advanced Trading Education Part 1", content: { type: 'video', id: 'advanced-trading-education-1', videoUrl: 'https://drive.google.com/file/d/1rbaYFSUQJYhhvEvL-NCujT6UbngFaeRf/view?usp=drivesdk' } },
          { title: "Advanced Trading Education Part 2", content: { type: 'video', id: 'advanced-trading-education-2', videoUrl: 'https://drive.google.com/file/d/1Cby3UnjzhYEXYOPwhsiKaxygGNbTHB9I/view?usp=drivesdk' } },
          { title: "Reading Candles", content: { type: 'video', id: 'reading-candles', videoUrl: 'https://drive.google.com/file/d/1jcfdF8UW11JfczwnPGCCqIQB3tisPIQ7/view?usp=drivesdk' } },
          { title: "Step 3 Quiz", content: { type: 'quiz', id: 'step3-quiz', quizUrl:'https://forms.fillout.com/t/7VBXmpuMQuus' } },
        ]
      }
    ]
  },
  {
    title: "How-to Videos",
    children: [
      {
        title: "Getting Started",
        children: [
          { title: "How to Journal", content: { type: 'video', id: 'how-to-journal', duration: '18 min', videoUrl: 'https://drive.google.com/file/d/1Yky_WK_J4VDlnHFY7VQaWV-s-SNSc6jb/view?usp=drivesdk', 
            additionalContent: [{
            type: 'button',
            text: 'Download the TTF Journal  HERE',
            link: 'https://docs.google.com/spreadsheets/d/1349PEPgWw_9Y2ODxVdOc_3u52EKvKgcE4JlwpKNHIXs/edit?gid=1175613825#gid=1175613825',
          },
          {
            type: 'button',
            text: 'Backtesting Podcast',
            link: 'https://www.youtube.com/watch?v=StFqiIUSw_E',
          }] } },
          { title: "How to use TradingView", content: { type: 'video', id: 'how-to-use-tradingview', duration: '17 min', videoUrl: 'https://drive.google.com/file/d/12pMZ824pF-Paz59tjWQjtC1J4iPijfrY/view?usp=drivesdk' } },
          { title: "TradingView Setup Video", content: { type: 'video', id: 'tradingview-setup', videoUrl: 'https://drive.google.com/file/d/1p1Q22IRaAqGAVN0GLiiEibE491IJtaQr/view?usp=drivesdk' } },
          { title: "How to use MT4 (Metatrader)", content: { type: 'video', id: 'how-to-use-mt4', duration: '27 min', videoUrl: 'https://drive.google.com/file/d/1XA_7HPmovBcl3zU5hGCuXiWw_2cYMpAO/view?usp=drivesdk' } },
          { title: "How to Enter a Trade from TradingView to MT4", content: { type: 'video', id: 'enter-trade-tradingview-to-mt4', duration: '6 min', videoUrl: 'https://drive.google.com/file/d/1F-MJwNzK1_gRcmNxCbbtDvuQYsPR96BF/view?usp=drivesdk' } },
          { title: "Contract Sizes on Indices and Lot Size on Forex", content: { type: 'video', id: 'contract-and-lot-sizes', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1LmYMfi4ILlSrxGT0fdRhgl4byLznkBFx/view?usp=drivesdk' } },
          { title: "Risk Management", content: { type: 'video', id: 'risk-management', duration: '60 min', videoUrl: 'https://drive.google.com/file/d/1xoKUWKx5HbkHTnNwg1RV_rLXPqlC3XF_/view?usp=drivesdk' } },
          { title: "Reading Candlesticks", content: { type: 'video', id: 'reading-candlesticks', duration: '36 min', videoUrl: 'https://drive.google.com/file/d/1m7ii3-27Eov_PQxZMrz5f8ACFrQcgM67/view?usp=drivesdk' } },
          { title: "Funded Accounts vs Personal Accounts", content: { type: 'video', id: 'funded-vs-personal-accounts', videoUrl: 'https://drive.google.com/file/d/1e2kfhqXG8E0dj864PCWCuSWWSDcNbhn2/view?usp=drivesdk' } },
          { title: "How to Set Indicator Alerts", content: { type: 'video', id: 'set-indicator-alerts', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/1XexW6kOEd00ivTvyEUcYaN9kxqDlNxrA/view?usp=drivesdk' } }
        ]
      },
      {
        title: "Continued Learning",
        children: [
          { title: "Multi Time Frame Analysis", content: { type: 'video', id: 'multi-time-frame-analysis', duration: '38 min', videoUrl: 'https://drive.google.com/file/d/1NeD6KS-KzdN0-T__PsXUJXXF6f6eXH_C/view?usp=drivesdk' } },
          { title: "How to Trade Consolidation", content: { type: 'video', id: 'trade-consolidation', duration: '15 min', videoUrl: 'https://drive.google.com/file/d/1e3vZ6pLwounueKGU5YtwEMlgmrbEgkcy/view?usp=drivesdk' } },
          { title: "Heikin Ashi Candles", content: { type: 'video', id: 'heikin-ashi-candles', duration: '12 min', videoUrl: 'https://drive.google.com/file/d/1_z_0Z_Z3mbE67kmh_MnsdVm0k6wjvlq3/view?usp=drivesdk' } },
          { title: "Lower Time Frame Liquidity", content: { type: 'video', id: 'lower-time-frame-liquidity', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/1hwYTXV15UNNejQXJuDm0xkxvu-4qqWC1/view?usp=drivesdk' } },
          { title: "How to Trade Divergences", content: { type: 'video', id: 'trade-divergences', duration: '11 min', videoUrl: 'https://drive.google.com/file/d/1diKYad7CEIfKw4oYFOgqf10fTXD_UlPe/view?usp=drivesdk' } },
          { title: "How to use the Any Oscillator", content: { type: 'video', id: 'use-any-oscillator', duration: '15 min', videoUrl: 'https://drive.google.com/file/d/1ArZzjMJj23xR02tewjb8R3QDt5doYpSF/view?usp=drivesdk' } },
          { title: "Trailing Stoploss", content: { type: 'video', id: 'trailing-stoploss', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1eDrtjUgtu1zOAY7FgpUfES_sIySz2m94/view?usp=drivesdk' } },
          { title: "How to Grow a Small Forex Account", content: { type: 'video', id: 'grow-small-forex-account', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1RPr3EisFOJzgBOF5_EvOgGdYkqV0Pm4t/view?usp=drivesdk' } }
        ]
      },
      {
        title: "Risk Calculator",
        children: [
          { title: "Installing MT4/5 Risk Calculator", content: { type: 'video', id: 'install-mt4-5-risk-calculator', duration: '5 min', videoUrl: 'https://drive.google.com/file/d/1cBVa1i-U2ZYUsY1Uz0QWucxh8PamUPyL/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'TTF Risk Calculator Download MT4 Version',
              link: 'https://drive.google.com/file/d/1qhCN8VeciYCrIZBb6sZMq5MlYBD0858I/view',
            },
            {
              type: 'button',
              text: 'MT5 Version',
              link: 'https://drive.google.com/file/d/1zN6ncb0MKIRXs7NTUPrxbDIpVO7NioXu/view',
            },
            {
              type: 'button',
              text: 'Mac Install Instructions',
              link: 'https://docs.google.com/document/d/101vldVp91oaILF63FW-ij1wtdkryzoy06SDuoA9eFYg/edit?tab=t.0',
            }]
           } },
          { title: "Entering A Trade In MT4", content: { type: 'video', id: 'enter-trade-mt4', duration: '12 min', videoUrl: 'https://drive.google.com/file/d/11w-YvBfZvBYj_5395muS4yvDaJVkmJSW/view?usp=drivesdk' } },
          { title: "Entering A Trade In MT5", content: { type: 'video', id: 'enter-trade-mt5', duration: '11 min', videoUrl: 'https://drive.google.com/file/d/1raiaXejJCBzHqOWyWwT2p8qpEWk7PMj_/view?usp=drivesdk' } },
          { title: "Risk Calculator Functions MT4", content: { type: 'video', id: 'risk-calculator-functions-mt4', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1u3zRUyDLu18lPH9y_BXvGXoWr-L2wUEz/view?usp=drivesdk' } },
          { title: "Risk Calculator Functions MT5", content: { type: 'video', id: 'risk-calculator-functions-mt5', duration: '7 min', videoUrl: 'https://drive.google.com/file/d/11u34cqgnaZM41p9dZydP09nlUgqWX3mV/view?usp=drivesdk' } },
          { title: "Process of Solving Issues MT4 & MT5", content: { type: 'video', id: 'solving-issues-mt4-mt5', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/1XRtkGop7dUkgAGK1nnljQno8vH5vbRcV/view?usp=drivesdk' } }
        ]
      }
    ]
  },
  {
    title: "Additional Courses",
    children: [
      {
        title: "Fundamentals",
        children: [
          { title: "Fundamentals Part 1", content: { type: 'video', id: 'fundamentals-part-1', duration: '10 min', videoUrl: 'https://drive.google.com/file/d/1q8DwCQnKcIVcv4jnh-6oJ5nxE5Amkw63/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Fundamentals Course Slides',
              link: 'https://docs.google.com/spreadsheets/d/1MKDzEK1KuobkUZdgkLfZSGlc0upzAsGMD1uQk9THcXk/edit?gid=0#gid=0',
            }]
           } },
          { title: "Fundamentals Part 2", content: { type: 'video', id: 'fundamentals-part-2', duration: '15 min', videoUrl: 'https://drive.google.com/file/d/1bYzbr8q0Q_5tp93JkGFBDih3CWd6NC5-/view?usp=drivesdk' } },
          { title: "Fundamentals Part 3", content: { type: 'video', id: 'fundamentals-part-3', duration: '11 min', videoUrl: 'https://drive.google.com/file/d/1erFAzPHoyUVfKj7RKHq2jm-w4ur-O_Sa/view?usp=drivesdk' } },
          { title: "Fundamentals Part 4", content: { type: 'video', id: 'fundamentals-part-4', duration: '19 min', videoUrl: 'https://drive.google.com/file/d/1yGFot7Un95OiMrildrAlXT7YR0bir-JS/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'TV Watchlist',
              link: 'https://www.tradingview.com/watchlists/90563604/',
            }]
           } },
          { title: "Fundamentals Part 5", content: { type: 'video', id: 'fundamentals-part-5', duration: '10 min', videoUrl: 'https://drive.google.com/file/d/1IdSW-ttdDdIiZpxNpIbCO_N3YhiYzZjV/view?usp=drivesdk' } }
        ]
      },
      {
        title: "Price Action Course",
        children: [
          { title: "Introduction", content: { type: 'video', id: 'price-action-intro', videoUrl: 'https://drive.google.com/file/d/12mJLssBtzBqQ_vs_69x2W3hjKvaC2FW6/view?usp=drivesdk' } },
          { title: "Candlestick Patterns", content: { type: 'video', id: 'candlestick-patterns', duration: '7 min', videoUrl: 'https://drive.google.com/file/d/1lvjpDiKHM6QJdxZvcK6QNxbCSsvbrr5Y/view?usp=drivesdk' } },
          { title: "Support and Resistance", content: { type: 'video', id: 'support-and-resistance', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/1UyFSRH1VYaW9N1wxykDIpqjJdlfJ3SUT/view?usp=drivesdk' } },
          { title: "Market Structure", content: { type: 'video', id: 'market-structure', duration: '5 min', videoUrl: 'https://drive.google.com/file/d/1UMfLbWVmiuYHqGqnhkGcacuSOLgic3ZS/view?usp=drivesdk' } },
          { title: "Chart Pattern: Continuations", content: { type: 'video', id: 'chart-pattern-continuations', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/1tKkbzEljCAwTrJtPyv0_Y0VBPyChWgzR/view?usp=drivesdk' } },
          { title: "Chart Patterns: Reversals", content: { type: 'video', id: 'chart-patterns-reversals', duration: '5 min', videoUrl: 'https://drive.google.com/file/d/181o9uMfkIUsdlvpEmY94PU2HfyofJzeg/view?usp=drivesdk' } },
          { title: "In Depth Support and Resistance", content: { type: 'video', id: 'in-depth-support-resistance', duration: '11 min', videoUrl: 'https://drive.google.com/file/d/1YylpLB84bOr2Fcm9n4rDen4oYAh7W80u/view?usp=drivesdk' } },
          { title: "Divergences", content: { type: 'video', id: 'divergences', duration: '22 min', videoUrl: 'https://drive.google.com/file/d/1X0pjmHey2Piyw4c2KCLZhbPXRJQiGkGK/view?usp=drivesdk' } },
          { title: "Price Action Tactics", content: { type: 'video', id: 'price-action-tactics', duration: '10 min', videoUrl: 'https://drive.google.com/file/d/1ixZZ0Wl6U6MU-dkD5z8qJUcUzdxcoIX7/view?usp=drivesdk' } },
          { title: "Multi-timeframe Analysis", content: { type: 'video', id: 'multi-timeframe-analysis', duration: '12 min', videoUrl: 'https://drive.google.com/file/d/189dvIGm2o9MYfyNZsgjHUXZiHUhOP90y/view?usp=drivesdk' } },
          { title: "Volume Analysis", content: { type: 'video', id: 'volume-analysis', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/14UWpk4IgkkxFYMxnjj0B3AK4b0ZbY-Lj/view?usp=drivesdk' } },
          { title: "Confluences: Putting It All Together", content: { type: 'video', id: 'confluences', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1ioencjqps19e0FQFIZ7fP1IktY01JCpa/view?usp=drivesdk' } },
          { title: "Conclusion: Congrats", content: { type: 'video', id: 'conclusion', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1zyWJCv3HPCjzTgokUezmhSBz1SRe1GBG/view?usp=drivesdk' } },
          { title: "Bonus: TTF Indicator Confluence", content: { type: 'video', id: 'ttf-indicator-confluence', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/17MSDIYAenbT6xuAetJvc5HowndP3Y8Vf/view?usp=drivesdk' } },
          {
              title: "Additional Resources",
              content: {
                type: 'text',
                id: 'additional-resources',
                content: `
                  <p><strong>Mini Series:</strong> Back to Basics video series explaining trade concepts in simple ways that are easy to understand. 
                  You can find these videos <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HehZyBaqANk-nPDKZItiqfX" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
                  
                  <p><strong>Price Action related content</strong> <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HcEf28azQ5dv2Ryhr13nRns" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
                  
                  <p><strong>Staff Trade Recaps & Livestreams:</strong></p>
                  <ul>
                    <li>Weekly Market Analysis with Bear <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HdmsFkXma2LhIIitfqXPM5B" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></li>
                    <li>Roxie's Weekly Trade Recaps <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HcCSCLqlY_lcaoKRY3vIlAl" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></li>
                  </ul>
                `
              }
            },
            { title: "Price Action Course: Slides", content: { 
              type: 'text', 
              id: 'price-action-course-slides',
              content: `Course Slides Download <a href="https://drive.google.com/file/d/1Cc6vMBCbvlWhd4srMYpTr6rdlABT3nps/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
            } 
          },
          { title: "Price Action Course: Workbook", content: { 
            type: 'text', 
            id: 'price-action-course-workbook',
            content: `Course Workbook Download <a href="https://drive.google.com/file/d/1ro0gmIEn7eHEYW9aYGHEipJTYZWyY3uy/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
          } 
        }
        ]
      },
      {
        title: "Inner Circle Trader (ICT) Course",
        children: [
          { title: "Introduction", content: { type: 'video', id: 'ict-intro', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/16Z1e_Iz0aMay9zslMSK5oJBGKM-aEJ3q/view?usp=drivesdk' } },
          { title: "Elements of a Trade Setup", content: { type: 'video', id: 'ict-trade-setup', duration: '7 min', videoUrl: 'https://drive.google.com/file/d/1JVuvuDlhLj-fL4odjAR35myNcGEh6MSb/view?usp=drivesdk' } },
          { title: "Institutional Order Flow & HTF Analysis", content: { type: 'video', id: 'ict-order-flow-htf', duration: '5 min', videoUrl: 'https://drive.google.com/file/d/1PMdIFmj_r1iIi7EvpSWj3vKR76U_Z-zg/view?usp=drivesdk' } },
          { title: "Premium Discount Arrays", content: { type: 'video', id: 'ict-premium-discount', duration: '14 min', videoUrl: 'https://drive.google.com/file/d/1GAHgPWNbWKwJN9x-s5AOzHxb644ytlZ2/view?usp=drivesdk' } },
          { title: "Retail Day Trading Traps", content: { type: 'video', id: 'ict-retail-traps', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/19P6bbzEFdf2BK6tTIQD1pzp3qJjCW2Vj/view?usp=drivesdk' } },
          { title: "Macro Economics - The Bigger Picture", content: { type: 'video', id: 'ict-macro-economics', duration: '64 min', videoUrl: 'https://drive.google.com/file/d/1qYANZ73B-wMBcyGZKx7QwpgyT-uAl3ry/view?usp=drivesdk' } },
          { title: "High Probability Trade Setups", content: { type: 'video', id: 'ict-high-probability', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1UBathdww_PBNK86kxvxQImTgmEwgIrYB/view?usp=drivesdk' } },
          { title: "Kill Zones", content: { type: 'video', id: 'ict-kill-zones', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/1LI3oSDB3jG0WRusOV67VgenaltMw7bFB/view?usp=drivesdk' } },
          { title: "Judas Swing", content: { type: 'video', id: 'ict-judas-swing', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/1u10zjvEURFMblljkX4nKsr3Q_kJ59sKK/view?usp=drivesdk' } },
          { title: "Silver Bullet", content: { type: 'video', id: 'ict-silver-bullet', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/15md4h2Uv2bEPTWhzLAwJ3gNs0Ms47R7a/view?usp=drivesdk' } },
          { title: "Power of Three", content: { type: 'video', id: 'ict-power-of-three', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1Veu8NnEDzMXuibvhaNPgPSus_5YULJ5w/view?usp=drivesdk' } },
          { title: "Optimal Trade Entry (OTE)", content: { type: 'video', id: 'ict-ote', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/17B0HZ5A36wjXll2sxDGh1Nk3Xd17FDhI/view?usp=drivesdk' } },
          { title: "Smart Money Technique (SMT)", content: { type: 'video', id: 'ict-smt', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/1XbtOZVsfLRbrdfKPnGQwusltTv1IJr68/view?usp=drivesdk' } },
          { title: "Chart Examples & Analysis", content: { type: 'video', id: 'ict-chart-examples', duration: '27 min', videoUrl: 'https://drive.google.com/file/d/1uxOk6Q6nOTuwPwyihv0Em-n_G32DRwTs/view?usp=drivesdk' } },
          { title: "Outro: Congratulations", content: { type: 'video', id: 'ict-outro', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1p1tmx8pLQRXIphd42G0I0awZc5mkaRhV/view?usp=drivesdk' } },
          { title: "Additional Resources", content: { 
            type: 'text', 
            id: 'ict-additional-resources',
            content: `
              <p><strong>Mini Series:</strong> You can find additional videos on important ICT concepts <a href="https://www.youtube.com/playlist?list=PLQl55pqba4Hd20UAbVnWfphPv2oTkiJqN" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
              <p><strong>Anatomy of a trade (trade recaps):</strong> <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HdzbKCVYfMHyNERHbWdlyx9" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
              <p><strong>ICT related content and streams:</strong> <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HfSI2uerIhG4mjxuZYaVonI" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
              <p><strong>Staff Trade Recaps & Livestreams:</strong></p>
              <p>Karl's ICT Livestreams (weekly) <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HdxXNqFyY6EgSiDclvSetl9" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></o>
              
            `
          } 
        },
        { title: "ICT Course: Slides", content: { 
          type: 'text', 
          id: 'ict-course-slides',
          content: `Course Slides Download <a href="https://drive.google.com/file/d/18YcLLr5sxFTBzILRMmsY0wkbXr62-kQV/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
        } 
      },
      { title: "ICT Course: Workbook", content: { 
        type: 'text', 
        id: 'ict-course-workbook',
        content: `Course Workbook Download <a href="https://drive.google.com/file/d/1LDivBHxT6mVmPL3KpFK-BP3P-OhfmJRk/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
      } 
    }
        ]
      },
      {
        title: "Smart Money Concepts (SMC)",
        children: [
          { title: "Introduction", content: { type: 'video', id: 'smc-intro', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1DfOA8mL4MH7dTH6Be8XjTXwjrhupl_GB/view?usp=drivesdk' } },
          { title: "Basic Market Structure", content: { type: 'video', id: 'smc-basic-structure', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/1iAI6UOF6p9bX3fAEqy78HkX4unWdZQfK/view?usp=drivesdk' } },
          { title: "3 Types Of Structure", content: { type: 'video', id: 'smc-structure-types', duration: '5 min', videoUrl: 'https://drive.google.com/file/d/1zQ0aZerCouyer-xLTti9A3iyZaIm67A6/view?usp=drivesdk' } },
          { title: "Premium vs Discount", content: { type: 'video', id: 'smc-premium-discount', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1CxejSbRZWK55JCzaK6spuVbTgQe2ywas/view?usp=drivesdk' } },
          { title: "Order Flow", content: { type: 'video', id: 'smc-order-flow', duration: '3 min', videoUrl: 'https://drive.google.com/file/d/11VcxANHVDkrXgjHoAzM5JXiGD1qiTLMC/view?usp=drivesdk' } },
          { title: "Supply and Demand", content: { type: 'video', id: 'smc-supply-demand', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/18s2xDKEiabfOIsTLHpSkEXa5g-v-edpR/view?usp=drivesdk' } },
          { title: "Multi-timeframe Supply and Demand", content: { type: 'video', id: 'smc-multi-timeframe', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1i7vF9k0-JE9jiPVZtykI0dZp7JY3ZHh-/view?usp=drivesdk' } },
          { title: "Liquidity and Liquidity Sweeps", content: { type: 'video', id: 'smc-liquidity', duration: '5 min', videoUrl: 'https://drive.google.com/file/d/1idjFnyCjOjLp0pXwJnPmVYCY-R1UerGi/view?usp=drivesdk' } },
          { title: "Flip Zones", content: { type: 'video', id: 'smc-flip-zones', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1HQzD_jEbTkYBcn7uSO6Ep5dhPI3cOUgG/view?usp=drivesdk' } },
          { title: "Inducement", content: { type: 'video', id: 'smc-inducement', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/1uPnVOYcM_ef_shdm-XHfQ8R72W3_nkNO/view?usp=drivesdk' } },
          { title: "Chart Examples on TradingView", content: { type: 'video', id: 'smc-chart-examples', duration: '15 min', videoUrl: 'https://drive.google.com/file/d/1UJpgXVDfOmitjF-mrA0alnq9AHqvqHMb/view?usp=drivesdk' } },
          { title: "Bonus: SMC Using TTF Indicators", content: { type: 'video', id: 'smc-ttf-indicators', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1QFmqlda35bwvfzFcdX8LkfwZyxipPspr/view?usp=drivesdk' } },
          { title: "Outro: Congratulations", content: { type: 'video', id: 'smc-outro', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1ESP5FMLGbaHKJP54n1arrj9lOfyMdJvG/view?usp=drivesdk' } },
          { title: "SMC Toolkit", content: { 
            type: 'video', 
            id: 'smc-toolkitt', 
            duration: '13 min', 
            videoUrl: 'https://drive.google.com/file/d/1SabQOUUzpUqzRcDNdXBqt5pNA-u5WnJX/view?usp=sharing',
            additionalContent: [{
              type: 'button',
              text: 'You can access the SMC Toolkit HERE',
              link: 'https://www.tradingview.com/script/9sOzcO32-TTF-SMC-Toolkit/'
            }]
          } 
        },
        { title: "SMC Toolkit - Dec 2023 Update", content: {
          type: 'video',
          id: 'smc-toolkit-update',
          duration: '1 min',
          videoUrl: 'https://drive.google.com/file/d/1YiNP2ud2yyddHzAbxweyHYc1JjlPBJbh/view?usp=sharing',
          additionalContent: [{
            type: 'button',
            text: 'You can access the SMC Toolkit HERE',
            link: 'https://www.tradingview.com/script/9sOzcO32-TTF-SMC-Toolkit/'
          }]
        }
      },
      { title: "Additional Resources", content: { 
        type: 'text', 
        id: 'smc-additional-resources',
        content: `
          <p><strong>Weekly Livestreams:</strong> Alex's DR/IDR Livestreams <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HezfcsQY2UQWI3f7rFhPL6L" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
          <p>Miss Navy & Jeremy <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HfzwOvjo7dnTJA9ewKK_uY_" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
        `
      } 
    },
    { title: "SMC Course: Slides", content: { 
      type: 'text', 
      id: 'smc-course-slides',
      content: `Course Slides Download <a href="https://drive.google.com/file/d/1uPFsCK5dW5cHiEK47naIzGVjfTkzwj8d/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
    } 
  },
  { title: "SMC Course: Workbook", content: { 
    type: 'text', 
    id: 'smc-course-workbook',
    content: `Course Workbook Download <a href="https://drive.google.com/file/d/1JzcW98H50w5CGLDfbcxIBd-a_p3TV-qI/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
  } 
}
        ]
      },
      {
        title: "Futures Trading Course",
        children: [
          { title: "Welcome", content: { type: 'video', id: 'futures-welcome', duration: '1 min', videoUrl: 'https://drive.google.com/file/d/1p6eW1qbuu-Xg0qjxBaLJHNLIVpzRPU8N/view?usp=drivesdk' } },
          { title: "Introduction to Futures", content: { type: 'video', id: 'futures-introduction', duration: '7 min', videoUrl: 'https://drive.google.com/file/d/1FbZEbKJ1eflInDF3TrFSU6xOY5iq1sux/view?usp=drivesdk' } },
          { title: "Benefits of Trading Futures", content: { type: 'video', id: 'futures-benefits', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/1sr5ZSp1tI4iuHRwpqVxNNYnPIddVAdla/view?usp=drivesdk' } },
          { title: "Futures Exchanges & Instruments", content: { type: 'video', id: 'futures-exchanges-instruments', duration: '13 min', videoUrl: 'https://drive.google.com/file/d/1m0mBaglS5K8a1puOb4Uq3Gbk-_43JDsY/view?usp=drivesdk' } },
          { title: "Contract Sizing: Micros vs Minis", content: { type: 'video', id: 'futures-contract-sizing', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/1Gniw1MOla26BeBtNmEK_QEyi8x9T-xtt/view?usp=drivesdk' } },
          { title: "Risk Management", content: { type: 'video', id: 'futures-risk-management', duration: '13 min', videoUrl: 'https://drive.google.com/file/d/1Evw9esNlPN2r2auD0kgNFn6eeIJSQEu_/view?usp=drivesdk' } },
          { title: "Prop Firms & Personal Funds", content: { type: 'video', id: 'futures-prop-firms-personal-funds', duration: '35 min', videoUrl: 'https://drive.google.com/file/d/1gD7QQa1spmWU-_-DELaUM3AeweB_MHkJ/view?usp=drivesdk' } },
          { title: "Trading Platforms", content: { type: 'video', id: 'futures-trading-platforms', duration: '25 min', videoUrl: 'https://drive.google.com/file/d/1fsAPMzC6l7D_mLMvfk87CK7uBeY15dlH/view?usp=drivesdk' } },
          { title: "Demo Trading", content: { type: 'video', id: 'futures-demo-trading', duration: '22 min', videoUrl: 'https://drive.google.com/file/d/1DdHmI9PHZK6u1-qMEVIOZ4MHvWnuEsOJ/view?usp=drivesdk' } },
          { title: "Futures & TTF Strategies", content: { type: 'video', id: 'futures-ttf-strategies', duration: '19 min', videoUrl: 'https://drive.google.com/file/d/1pVIuteD6B4MKlLsmgXfuE-fJtYOf1bte/view?usp=drivesdk' } },
          { title: "Contract Rollover", content: { type: 'video', id: 'futures-contract-rollover', duration: '8 min', videoUrl: 'https://drive.google.com/file/d/1BDUcCHLsNT6WjjF9gEJwIbYV6EI42bbd/view?usp=drivesdk' } },
          { title: "NinjaTrader", content: { type: 'video', id: 'futures-ninjatrader', duration: '19 min', videoUrl: 'https://drive.google.com/file/d/1tJ-5x2Gif8Im8Odr6jJNfV9rKeOWb-4X/view?usp=drivesdk' } },
          { title: "Tradovate", content: { type: 'video', id: 'futures-tradovate', duration: '10 min', videoUrl: 'https://drive.google.com/file/d/1600_7-KrhHWJNewA_Kl7YjVjXtMd-mms/view?usp=drivesdk' } },
          { title: "Additional Resources", content: { 
            type: 'text', 
            id: 'futures-additional-resources',
            content: `
              <p><strong>Futures Video Series:</strong> short videos on topics specific to futures. Answers to common questions, such as contract rollover, prop firms, payouts, risk management, profit splits, fees, volume, and much more. You can find these videos <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HcNfrQ1cU1to5wLnAVY5Cih" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
              <p>Futures related livestreams and short videos <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HdIXMtQ2Bl2_KMms83nYjMA" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></p>
              <p><strong>Staff Trade Recaps & Livestreams:</strong></p>
              <ul>
                <li>Roxie's Weekly Trade Recaps <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HcCSCLqlY_lcaoKRY3vIlAl" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></li>
                <li>Alex's DR/IDR Livestreams (weekly) <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HezfcsQY2UQWI3f7rFhPL6L" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></li>
                <li>Karl's ICT Livestreams (weekly) <a href="https://www.youtube.com/playlist?list=PLQl55pqba4HdxXNqFyY6EgSiDclvSetl9" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a></li>
              </ul>
            `
          } 
        },
        { title: "Futures Trading Course: Slides", content: { 
          type: 'text', 
          id: 'futures-course-slides',
          content: `Course Slides Download <a href="https://drive.google.com/file/d/1dBSNaQ9ZlN3TXD47nwASS9Jj6l67VmeS/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
        } 
      },
      { title: "Futures Trading Course: Workbook", content: { 
        type: 'text', 
        id: 'futures-course-workbook',
        content: `Course Workbook Download <a href="https://drive.google.com/file/d/1-6S0l6MAKT1bXhWQ6h7D59KDkUbubxkI/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
      } 
    }
        ]
      },
      {
        title: "Crypto Course",
        children: [
          { title: "What is Cryptocurrency", content: { type: 'video', id: 'crypto-what-is', duration: '6 min', videoUrl: 'https://drive.google.com/file/d/1ydtIFXcDTD0W22pNkBuBNqZ3EoGkueME/view?usp=drivesdk' } },
          { title: "Brief History of Crypto", content: { type: 'video', id: 'crypto-history', duration: '5 min', videoUrl: 'https://drive.google.com/file/d/1FRalfdqUf1cxYwtplUhOBjaqrQyVlHND/view?usp=drivesdk' } },
          { title: "Blockchain and Decentralization", content: { type: 'video', id: 'crypto-blockchain', duration: '6 min', videoUrl: 'https://drive.google.com/file/d/18zR_n-z4CGcK8QbpFun6qfc55EsXAzjb/view?usp=drivesdk' } },
          { title: "Risks Challenges of Crypto", content: { type: 'video', id: 'crypto-risks-challenges', duration: '2 min', videoUrl: 'https://drive.google.com/file/d/19ahSMSaGL1gHTTyqJ5dufqEtDVL5TSQK/view?usp=drivesdk' } },
          { title: "Understanding Price Volatility", content: { type: 'video', id: 'crypto-volatility', duration: '4 min', videoUrl: 'https://drive.google.com/file/d/1G303_nN_jM_Jo_UUp3BF6ifIVQQNkjok/view?usp=drivesdk' } },
          { title: "Market Cap Tokenomics Price Dynamics", content: { type: 'video', id: 'crypto-market-cap', duration: '8 min', videoUrl: 'https://drive.google.com/file/d/1NPsDEkenDEePik9OyncRJHaa98aEkaB1/view?usp=drivesdk' } },
          { title: "Understand Supply & Demand", content: { type: 'video', id: 'crypto-supply-demand', duration: '7 min', videoUrl: 'https://drive.google.com/file/d/1obAGhSMXN8o1sxkK9f3iqia66PkXQcel/view?usp=drivesdk' } },
          { title: "Bitcoin Halving Cycle", content: { type: 'video', id: 'crypto-halving-cycle', duration: '6 min', videoUrl: 'https://drive.google.com/file/d/1SoKu6UdwBZHetoDYjVM0cE5V9dnMVILF/view?usp=drivesdk' } },
          { title: "Stages of Bitcoin Crypto Seasons", content: { type: 'video', id: 'crypto-seasons', duration: '8 min', videoUrl: 'https://drive.google.com/file/d/1SpGhp90vx3QfCfZJmrXkyToxeyB0jCEY/view?usp=drivesdk' } },
          { title: "Crypto Market Cap Dominance", content: { type: 'video', id: 'crypto-market-dominance', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1tahk5BON3TR0SYDCUinqWf8gEA_y_ESN/view?usp=drivesdk' } },
          { title: "Fundamental Analysis", content: { type: 'video', id: 'crypto-fundamental-analysis', duration: '5 min', videoUrl: 'https://drive.google.com/file/d/1vOUNxiJ19whIu23nY2tAzqBhFkR-F3fJ/view?usp=drivesdk' } },
          { title: "Technical Analysis", content: { type: 'video', id: 'crypto-technical-analysis', duration: '8 min', videoUrl: 'https://drive.google.com/file/d/1lZObAyhs6e2eXQ0MFaTdBhoBhxIkq_V8/view?usp=drivesdk' } },
          { title: "Understanding Crypto Projects Sectors", content: { type: 'video', id: 'crypto-projects-sectors', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1Oh1ne6me6QlWBxyoxC8-57yunyZS9C2L/view?usp=drivesdk' } },
          { title: "Crypto Exchanges Wallets and Security", content: { type: 'video', id: 'crypto-wallets-security', duration: '6 min', videoUrl: 'https://drive.google.com/file/d/1FjIWMP-otxekR6tefROKwYctXbgjtIE-/view?usp=drivesdk' } },
          { title: "Update on Crypto Exchanges Wallets and Security", content: { type: 'video', id: 'crypto-wallets-security-update', duration: '12 min', videoUrl: 'https://drive.google.com/file/d/1JaOR-GOWlfCgJy7sRkA7SEaDJdGHmIV8/view?usp=drivesdk' } },
          { title: "Building a Crypto Portfolio", content: { type: 'video', id: 'crypto-portfolio-building', duration: '9 min', videoUrl: 'https://drive.google.com/file/d/1-oNRRI9vjFDMTb8oun9ffIrCDmkmGhQJ/view?usp=drivesdk' } },
          { title: "Determining Sell Levels", content: { type: 'video', id: 'crypto-sell-levels', duration: '6 min', videoUrl: 'https://drive.google.com/file/d/1nEGD8bSImG3AxI5Dv-_6MnGH5J3MqBsS/view?usp=drivesdk' } },
          { title: "Tracking Portfolio Performance Taxes", content: { type: 'video', id: 'crypto-performance-taxes', duration: '10 min', videoUrl: 'https://drive.google.com/file/d/1AIomx7GjzksMXxtCQHcdZSA6PIftR9i2/view?usp=drivesdk' } },
          { title: "Introduction to Staking", content: { type: 'video', id: 'crypto-staking', duration: '8 min', videoUrl: 'https://drive.google.com/file/d/1xuzSpjJFOTnapTkVLkrh4zle756gf4qX/view?usp=drivesdk' } },
          { title: "Conclusion of Crypto Course", content: { type: 'video', id: 'crypto-conclusion', duration: '3 min', videoUrl: 'https://drive.google.com/file/d/1n_ddnG-yBQ5E4EyqkZCjFQN5S2uUvkpb/view?usp=drivesdk' } },
          { title: "Crypto Course: Slides", content: { 
            type: 'text', 
            id: 'crypto-course-slides',
            content: `Course Slides Download <a href="https://drive.google.com/file/d/1u-ELlPGr3OXF9xpM1XPZm1IfC9REeBX6/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
          } },
          { title: "Crypto Course: Workbook", content: { 
            type: 'text', 
            id: 'crypto-course-workbook',
            content: `Course Workbook Download <a href="https://drive.google.com/file/d/11b15nmgGLbA7737fLxSoEkeQfQNr7lK4/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
          } }
        ]
      }
    ]
  },
  {
    title: "Strategies",
    children: [
      { title: "Watch First", content: { 
        type: 'video', 
        id: 'watch-first', 
        videoUrl: 'https://drive.google.com/file/d/1EXqRlfopAkiHt3xMACK15KxVuQI72DFp/view?usp=drivesdk',
        additionalContent: [{
          type: 'button',
          text: 'Strategy Indicator Overview',
          link: 'https://www.youtube.com/watch?v=Uoz1J1Jzxug'
        }]
      } },
      { title: "How to Set Indicator Alerts", content: { type: 'video', id: 'set-indicator-alerts', videoUrl: 'https://drive.google.com/file/d/1WSwqdh-mnVKT4FGj2_irJlG7lSspVw_o/view?usp=drivesdk' } },
      {
        title: "3 Line Strike",
        children: [
          { title: "How to Trade a 3 Line Strike", content: { 
            type: 'video', 
            id: '3-line-strike-how-to', 
            videoUrl: 'https://drive.google.com/file/d/10If06TCp35dkR4XNAs2C3LnbXY7Ig2oE/view?usp=drivesdk',
            additionalContent: [
              {
                type: 'button',
                text: '3 Line Strike Time of Day to trade',
                link: 'https://www.youtube.com/watch?v=ot0qFIhXylw'
              },
              {
                type: 'button',
                text: '3 Line Strike Improved',
                link: 'https://www.youtube.com/watch?v=nTHUiuyi8CY'
              },
              {
                type: 'button',
                text: '3 Line Strike Highest Win Rate',
                link: 'https://www.youtube.com/watch?v=wbfXaqjIrJ0'
              },
              {
                type: 'button',
                text: '3 Line Strike - VWAP update',
                link: 'https://www.youtube.com/watch?v=Z__54ssczD0'
              },
              {
                type: 'button',
                text: '3 Line Strike - Price Action',
                link: 'https://www.youtube.com/watch?v=T3t2DXGyBnU'
              }
            ]
          } },
          { title: "Additional Resources", content: { 
            type: 'text', 
            id: '3-line-strike-resources',
            content: `
              <a href="https://www.youtube.com/watch?v=RumMvMVxdPM" target="_blank" rel="noopener noreferrer" class="content-button">3 Line Strike Strategy Wars</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=rafjz_7zDg0" target="_blank" rel="noopener noreferrer" class="content-button">2023 Extravaganza</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=nTHUiuyi8CY" target="_blank" rel="noopener noreferrer" class="content-button">5 Minute Scalping Strategy Improved (The Moving Average)</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=wbfXaqjIrJ0" target="_blank" rel="noopener noreferrer" class="content-button">5 Minute Scalping Strategy HIGHEST WIN RATE (The Moving Average)</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=Z__54ssczD0" target="_blank" rel="noopener noreferrer" class="content-button">5 Minute Scalping Strategy VWAP UPDATE (The Moving Average)</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=T3t2DXGyBnU" target="_blank" rel="noopener noreferrer" class="content-button">Best Scalping Entry - Price action (The Moving Average)</a>
            `
          } }
        ]
      },
      {
        title: "Brute Force",
        children: [
          { title: "Brute Force V2", content: { 
            type: 'video', 
            id: 'brute-force-v2', 
            videoUrl: 'https://drive.google.com/file/d/15aa3RRjCG_fx4XsFyjCJ-rfmF-M18U00/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Backtesting Data Sheet',
              link: 'https://docs.google.com/spreadsheets/d/1u7xhcM2-JgUW6nQuCrA7j-Gm29Usrdr58hMAfAeUj0M/edit?gid=1118601917#gid=1118601917'
            }]
          } },
          { title: "Additional Resources", content: { 
            type: 'text', 
            id: 'brute-force-resources',
            content: `
              <a href="https://www.youtube.com/watch?v=3O5x5BsL4Og" target="_blank" rel="noopener noreferrer" class="content-button">Brute Force Strategy Wars</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=Aluzj6ioHbA" target="_blank" rel="noopener noreferrer" class="content-button">2023 Extravaganza</a>
            `
          } },
        ]
      },
      {
        title: "DeLorean",
        children: [
          { title: "DeLorean Indicator", content: { 
            type: 'video', 
            id: 'delorean-indicator', 
            videoUrl: 'https://drive.google.com/file/d/1QBCKXW8kHy52A1JbjWyH-ibncEafMwK1/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Backtesting Data Sheet',
              link: 'https://docs.google.com/spreadsheets/d/1pYu9JVMYKn4V0lVNVzjr0e7D4sQsJ3sGb68vA_ctSzs/edit?gid=1385102799#gid=1385102799'
            }]
          } },
          { title: "Intermediate DeLorean", content: { type: 'video', id: 'delorean-intermediate', videoUrl: 'https://drive.google.com/file/d/1AaQhtR6JEGuAbo3a_cSbH8HMYRW1KEH3/view?usp=drivesdk' } },
          { title: "Additional Resources", content: { 
            type: 'text', 
            id: 'delorean-resources',
            content: `
              <a href="https://www.youtube.com/watch?v=PkBpm2nP2cU" target="_blank" rel="noopener noreferrer" class="content-button">Delorean Strategy Wars</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=2RDRyR8o3nI" target="_blank" rel="noopener noreferrer" class="content-button">2023 Extravaganza</a>
            `
          } }
        ]
      },
      {
        title: "Happy Trail",
        children: [
          { title: "Introduction", content: { type: 'video', id: 'happy-trail-introduction', videoUrl: 'https://drive.google.com/file/d/1St39fFCvx4shVFCN5U1-jGByM4ZidD-6/view?usp=drivesdk' } },
          { title: "Case 1", content: { type: 'video', id: 'happy-trail-case1', videoUrl: 'https://drive.google.com/file/d/1TR18bkPB5v_XqAOLMnk1P9mAs8wHKfDX/view?usp=drivesdk' } },
          { title: "Case 2", content: { type: 'video', id: 'happy-trail-case2', videoUrl: 'https://drive.google.com/file/d/154AqXXuKpAR5Xg9UYnpfZhIemHvH08P5/view?usp=drivesdk' } },
          { title: "Case 3", content: { type: 'video', id: 'happy-trail-case3', videoUrl: 'https://drive.google.com/file/d/15AZDkgqwrwh6RhI6wT2mKq8HL6M4keFq/view?usp=drivesdk' } },
          { title: "Case 4", content: { type: 'video', id: 'happy-trail-case4', videoUrl: 'https://drive.google.com/file/d/14HbxtghWv8IKEEfpPdoD0Y7VE4trIEhS/view?usp=drivesdk' } },
          { title: "Backtesting Data", content: { 
            type: 'video', 
            id: 'happy-trail-backtest', 
            duration: '6 min', 
            videoUrl: 'https://drive.google.com/file/d/1cidcEU1fWg7jdUhiiLtzaA55oSnJB8Y6/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Access the backtesting data',
              link: 'https://docs.google.com/spreadsheets/d/1kJEDvdmKW_DqoZ_le2D9xT77kkiLXlivZfoD_xiA1W4/edit'
            }]
          } },
          { title: "Additional Resources", content: { 
            type: 'text', 
            id: 'happy-trail-resources',
            content: `
              <a href="https://www.youtube.com/watch?v=VaDjt4d7Ryw" target="_blank" rel="noopener noreferrer" class="content-button">Happy Trail Strategy Wars</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=8mxdrRA23oo" target="_blank" rel="noopener noreferrer" class="content-button">25 Days of Tradesmas</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=H0TsLcz1Nqk" target="_blank" rel="noopener noreferrer" class="content-button">2023 Extravaganza</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=paqDWSzTH4I" target="_blank" rel="noopener noreferrer" class="content-button">Happy Trail w/ SMC Toolkit (The Moving Average)</a>
            `
          } }
        ]
      },
      {
        title: "Outback",
        children: [
          { title: "Outback Introduction", content: { type: 'video', id: 'outback-introduction', videoUrl: 'https://drive.google.com/file/d/1Zxh4DasBfgWN1bm45RjRzeuA7HcdT_yl/view?usp=drivesdk' } },
          { title: "Case 1", content: { type: 'video', id: 'outback-case1', videoUrl: 'https://drive.google.com/file/d/1nVnnEpKyVf3sipcKdeVaGw1Ts3phy3Be/view?usp=drivesdk' } },
          { title: "Case 1 HTF Structure", content: { type: 'video', id: 'outback-case1-htf', videoUrl: 'https://drive.google.com/file/d/11hVZDczmfD48CO3_0BKe1VH-UPyeLoO6/view?usp=drivesdk' } },
          { title: "Case 2 Intermediate", content: { 
            type: 'video', 
            id: 'outback-case2-intermediate', 
            videoUrl: 'https://drive.google.com/file/d/1yb9cnGhiPdoj_EGubGLCQKfKHi-t-Wxw/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Interactive Journal',
              link: 'https://docs.google.com/spreadsheets/d/1mh32RHWPQ_BCXQkqIjmximiR87c3nI5Aj3mU0jy3Tuc/edit?gid=1329131372#gid=1329131372'
            }]
          } },
          { title: "Case 2 Beginner", content: { 
            type: 'video', 
            id: 'outback-case2-beginner', 
            videoUrl: 'https://drive.google.com/file/d/1VGMG4KhE3QgvDCveYzYteAzHAd742ZzE/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Backtesting Data',
              link: 'https://docs.google.com/spreadsheets/d/1xpTIDnWptT7xinh2LL4N4eNuUxU5ib-9BzS4QgWfwSU/edit'
            }]
          } },
          { title: "Outback Alerts", content: { type: 'video', id: 'outback-alerts', videoUrl: 'https://drive.google.com/file/d/1wZ_fBihWGWSMvCPuWfy_7PKM9sUx7g-O/view?usp=drivesdk' } },
          { title: "Additional Resources", content: { 
            type: 'text', 
            id: 'outback-resources',
            content: `
              <a href="https://www.youtube.com/watch?v=XyF3XbITSpQ" target="_blank" rel="noopener noreferrer" class="content-button">Outback Strategy Wars</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=zVcnNofxQTI" target="_blank" rel="noopener noreferrer" class="content-button">Lemon's Hong Kong Gap</a>
              <br><br>
              <a href="https://www.youtube.com/watch?v=Ycy5hBz_RdU" target="_blank" rel="noopener noreferrer" class="content-button">2023 Extravaganza</a>
            `
          } }
        ]
      },
      {
        title: "Fractal Frenzy (SM)",
        children: [
          { title: "Fractal Frenzy Intro and Settings", content: { type: 'video', id: 'fractal-frenzy-intro', videoUrl: 'https://drive.google.com/file/d/1MCV5_bXhwa9vsKmVIy5JrocW6iI3JIvj/view?usp=drivesdk' } },
          { title: "Trading Fractal Frenzy", content: { 
            type: 'video', 
            id: 'fractal-frenzy-trading', 
            videoUrl: 'https://drive.google.com/file/d/1ksoBk38qD8WmDkkqX2lb5UQIwmQ_UWvP/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Additional Trading Videos',
              link: 'https://www.youtube.com/playlist?list=PLQl55pqba4HcGs7gmuid7TMp_UTwPIehi'
            }]
          } },
          { title: "Setting Alerts and Backtesting Numbers", content: { 
            type: 'video', 
            id: 'fractal-frenzy-alerts-backtest', 
            videoUrl: 'https://drive.google.com/file/d/1vYg3_dR2gtNF_YLp_Js5jmryOSxxhqQa/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Backtesting Data Sheet',
              link: 'https://docs.google.com/spreadsheets/d/1YwdnU4-k_bsQDNX_9iRK1WxLl9aBWHH-EZWcmO9A724/edit?gid=2098762703#gid=2098762703'
            }]
          } }
        ]
      },
      {
        title: "Wave Rider",
        children: [
          { title: "Wave Rider Strategy Explained", content: { type: 'video', id: 'wave-rider-explained', videoUrl: 'https://drive.google.com/file/d/1VebfqLjqqPpXSm-GZOA7sJoKnHs4QJ2e/view?usp=drivesdk' } },
          { title: "Back Tested Data", content: { 
            type: 'video', 
            id: 'wave-rider-backtest', 
            videoUrl: 'https://drive.google.com/file/d/1AyAO24bK1nDbTkGs5g55xUnSyhe7UsYu/view?usp=drivesdk',
            additionalContent: [{
              type: 'button',
              text: 'Backtesting Data Sheet',
              link: 'https://docs.google.com/spreadsheets/d/1cj-qC7wDkDwIIjLjeqHL5RwvNBbJXHqQEw7vj1GmKl0/edit?gid=64445844#gid=64445844'
            }]
          } },
          { title: "Wave Rider with Market Structure", content: { type: 'video', id: 'wave-rider-market-structure', videoUrl: 'https://drive.google.com/file/d/1dG0k9wYkMpw-3y2bNtDpPVIyZrkfa5Gc/view?usp=drivesdk' } },
          { title: "Wave Rider Alerts", content: { type: 'video', id: 'wave-rider-alerts', videoUrl: 'https://drive.google.com/file/d/1NPIAVVWNakhkjzd-VSYmvSjs5P_JLnek/view?usp=drivesdk' } },
          { title: "Signal Print Troubleshooting", content: { type: 'video', id: 'wave-rider-troubleshoot', videoUrl: 'https://drive.google.com/file/d/1jQ0whAg3KusMK2h7YSC1PkqUYgHKvrn3/view?usp=drivesdk' } }
        ]
      },
      {
        title: "Price Action Toolkit",
        children: [
          { title: "Price Action Toolkit Intro", content: { type: 'video', id: 'price-action-toolkit-intro', videoUrl: 'https://drive.google.com/file/d/1-p-H4WejbcMc62S57p3yRRekBxn7Z8kE/view?usp=drivesdk' } },
          { title: "Indicator Settings and Alerts Part 1", content: { type: 'video', id: 'price-action-toolkit-settings1', videoUrl: 'https://drive.google.com/file/d/1tewZwyAW1FG_2J9N0tVDr-lzUbJBp-xU/view?usp=drivesdk' } },
          { title: "Indicator Settings and Alerts Part 2", content: { type: 'video', id: 'price-action-toolkit-settings2', videoUrl: 'https://drive.google.com/file/d/1qmQpiehoFvXXohDUBQ_G2eOMVuK-aXe9/view?usp=drivesdk' } },
          { title: "Explaining The Fibonacci Zone", content: { type: 'video', id: 'price-action-toolkit-fibonacci-zone', videoUrl: 'https://drive.google.com/file/d/1PiVBXgwWHaYJf5HxwTdnCb1TNc2H7HMG/view?usp=drivesdk' } },
          { title: "Fibonacci Zone Troubleshooting", content: { type: 'video', id: 'price-action-toolkit-fibonacci-troubleshoot', videoUrl: 'https://drive.google.com/file/d/1sL1IeOhitke2rge8i8gFzZIvuz31BitT/view?usp=drivesdk' } },
          { title: "Quality Of Life Filters", content: { type: 'video', id: 'price-action-toolkit-filters', videoUrl: 'https://drive.google.com/file/d/1zXYOL5RvE3rDBMAU2qumaWRJF3clqUhy/view?usp=drivesdk' } },
          { title: "Volume Candles", content: { type: 'video', id: 'price-action-toolkit-volume', videoUrl: 'https://drive.google.com/file/d/1RfStD_u-RKaCeueHB3UmsCEEj2pwv50N/view?usp=drivesdk' } },
          { title: "Putting It All Together", content: { type: 'video', id: 'price-action-toolkit-together', videoUrl: 'https://drive.google.com/file/d/1Tq0XjRJRZKzwrZy9YuOTN2n_C3rrqz-I/view?usp=drivesdk' } }
        ]
      }
    ]
  },
  {
    title: "Trading Mindset Education",
    children: [
      { title: "Beginner Trader Psychology", content: { 
  type: 'video', 
  id: 'beginner-psychology', 
  videoUrl: 'https://drive.google.com/file/d/1Obw1A66QqC4RbsYZdnzKm7skHggfH8kx/view?usp=drivesdk',
  additionalContent: [{
    type: 'button',
    text: 'Mindset Resource Slide',
    link: 'https://mega.nz/file/8hBigCzQ#uVSQIHNi101AJsz_gq5gkU7RMWGEQ8pwqLf0jhvF9YE'
  }]
} },
{ title: "Mindset Resource Guide", content: { 
  type: 'text', 
  id: 'mindset-resource-guide',
  content: `Trading Mindset Resource Guide: videos, books, tips and other resources
and other resources for improving your mindset <a href="https://docs.google.com/document/d/1AvQqCcTSXLmy_0EDKrUrfGg0eDNMoQ-57HUswZkijmE/edit?usp=sharing" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
} },
      { title: "The Importance of Demo Trading and Live Trading", content: { type: 'video', id: 'demo-live-trading', videoUrl: 'https://drive.google.com/file/d/1z6_xunnh1UrXrqCgcV3omjrxcVeXh0YJ/view?usp=drivesdk' } },
      { title: "Process vs Outcome", content: { type: 'video', id: 'process-vs-outcome', videoUrl: 'https://drive.google.com/file/d/1C_ILBL-1_hWdGMCf8Ga4Fc96wS6Z_B8U/view?usp=drivesdk' } },
      { title: "Fundamental Truth of Trading", content: { type: 'video', id: 'fundamental-truth', videoUrl: 'https://drive.google.com/file/d/1tOpzfeFwctFFmwafyXPGmOPf5boQsbj-/view?usp=drivesdk' } },
      { title: "Mindset Journal", content: { type: 'video', id: 'mindset-journal', videoUrl: 'https://drive.google.com/file/d/1Nd8cyBr2Xywm3ZT_HV0-5Lst7U8pDntR/view?usp=drivesdk' } },
      {
        title: "Mindset Mini Series",
        children: [
          { title: "Mindset Intro", content: { type: 'video', id: 'mindset-intro', videoUrl: 'https://drive.google.com/file/d/15zFlqW9kjJ9f-jvinPk0jLvAwApbw3_5/view?usp=drivesdk' } },
          { title: "Quality Over Quantity", content: { type: 'video', id: 'mindset-quality-over-quantity', videoUrl: 'https://drive.google.com/file/d/1rZU3cXHTxdBGTcekWQ0Zz0IvMxn4ztfQ/view?usp=drivesdk' } },
          { title: "Trust the Analysis Take the Trade", content: { type: 'video', id: 'mindset-trust-analysis', videoUrl: 'https://drive.google.com/file/d/1vTq8rRNhjBf9hZJk03_umGd0eUHt8_5o/view?usp=drivesdk' } },
          { title: "Back Testing", content: { type: 'video', id: 'mindset-back-testing', videoUrl: 'https://drive.google.com/file/d/1Ktm7BeRwtGpbQ5PTIBM90yny_hvemPlO/view?usp=drivesdk' } },
          { title: "Trading One Instrument", content: { type: 'video', id: 'mindset-one-instrument', videoUrl: 'https://drive.google.com/file/d/1o5jufyJd6T-JHaoqzyARaMKIoiMwq_Xj/view?usp=drivesdk' } },
          { title: "Taking a Break", content: { type: 'video', id: 'mindset-taking-break', videoUrl: 'https://drive.google.com/file/d/1JQAa5M5uGEQnFEmSCysFIssv7L4qOFrk/view?usp=drivesdk' } },
          { title: "Returning from a Break", content: { type: 'video', id: 'mindset-returning-break', videoUrl: 'https://drive.google.com/file/d/1Oh-fQToqGoa3qoLcJ6kBDhV-WPKFA-e-/view?usp=drivesdk' } },
          { title: "Fear Loss and Failure", content: { type: 'video', id: 'mindset-fear-loss-failure', videoUrl: 'https://drive.google.com/file/d/1xfbGsiLM2oA5EpXP4pzVLVrY67RQFhbw/view?usp=drivesdk' } }
        ]
      }
    ]
  },
  {
    title: "Resources",
    children: [
      {
        title: "Tools",
        children: [
          { title: "TTF Glossary", content: { 
            type: 'text', 
            id: 'ttf-glossary',
            content: `TTF Trade Glossary <a href="https://docs.google.com/spreadsheets/d/1uL7jeLfUC9TXKVBJBY-TOYu4cPx4SyMLCxELfFF85Yg/edit?gid=1361207560#gid=1361207560" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
          } },
          { title: "TTF Trading Journal", content: { 
            type: 'video', 
            id: 'ttf-trading-journal', 
            duration: '18 min',
            videoUrl: 'https://drive.google.com/file/d/1fBktaMUL17RyviTqMwlRgIF7u35Zamoj/view?usp=drive_link',
            additionalContent: [{
              type: 'button',
              text: 'TTF Trade Journal',
              link: 'https://docs.google.com/spreadsheets/d/1349PEPgWw_9Y2ODxVdOc_3u52EKvKgcE4JlwpKNHIXs/edit?gid=1175613825#gid=1175613825'
            }]
          } },
          { title: "TTF Mindset Journal", content: { type: 'video', id: 'ttf-mindset-journal', duration: '11 min', videoUrl:'https://drive.google.com/file/d/19FMcFDYzBmLTdJrahm4KSuM99r9Be8hY/view?usp=drive_link' } },
          { title: "TTF 20 Pip Challenge", content: { 
            type: 'text', 
            id: 'ttf-20-pip-challenge',
            content: `TTF 20 Pip Trading Challenge <a href="https://docs.google.com/spreadsheets/d/1-pBaJTEuhZijsOUL2XM-JVo57bpxYpTFs0mbAvbaij0/edit?gid=0#gid=0" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>
            <br><br>
            Arty's explanation of the 20 Pip Challenge <a href="https://www.youtube.com/watch?v=tHPqhvN0gYU" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>
            <br><br>
            Additional 20 Pip Challenge videos <a href="https://www.youtube.com/watch?v=xJZ5m2okeFc" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a> and <a href="https://www.youtube.com/watch?v=xxrgGE7rgb8" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
           } },
           { title: "TTF Road Map Guide", content: { 
            type: 'video', 
            id: 'ttf-road-map-guide', 
            duration: '4 min',
            videoUrl: 'https://drive.google.com/file/d/1Y26lSqk697RM8c1wDkuaGKCr5tul5XAO/view?usp=drive_link',
            additionalContent: [{
              type: 'button',
              text: 'TTF Road Map Guide',
              link: 'https://docs.google.com/spreadsheets/d/1N3qF2_QJ-cws3JHeU1WNKi2G3WDhGJw6WLTQ54YLtUk/edit?gid=49985015#gid=49985015'
            }]
           } },
           { title: "Crypto Workbook", content: { 
            type: 'text', 
            id: 'crypto-workbook',
            content: `Course Workbook Download <a href="https://drive.google.com/file/d/11b15nmgGLbA7737fLxSoEkeQfQNr7lK4/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
           } },
           { title: "ICT Workbook", content: { 
            type: 'text', 
            id: 'ict-workbook',
            content: `Course Workbook Download <a href="https://drive.google.com/file/d/1LDivBHxT6mVmPL3KpFK-BP3P-OhfmJRk/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
           } },
           { title: "Futures Workbook", content: { 
            type: 'text', 
            id: 'futures-workbook',
            content: `Course Workbook Download <a href="https://drive.google.com/file/d/1-6S0l6MAKT1bXhWQ6h7D59KDkUbubxkI/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
           } },
           { title: "Price Action Workbook", content: { 
            type: 'text', 
            id: 'price-action-workbook',
            content: `Course Workbook Download <a href="https://drive.google.com/file/d/15PAPNzymerXuBMcVokhI9qv7r04NeHwO/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
           } },
           { title: "SMC Workbook", content: { 
            type: 'text', 
            id: 'smc-workbook',
            content: `Course Workbook Download <a href="https://drive.google.com/file/d/1fTfX7Efc33-wIhV9X3ETCyitXCBtV10_/view" target="_blank" rel="noopener noreferrer" class="content-button">HERE</a>`
           } },
           { title: "SMC Toolkit", content: { 
            type: 'video', 
            id: 'smc-toolkit', 
            duration: '13 min',
            videoUrl: 'https://drive.google.com/file/d/191zSH57k4wssaD4YxnRrxe-Jssrn6Inu/view?usp=drive_link',
            additionalContent: [{
              type: 'button',
              text: 'You can access the SMC Toolkit',
              link: 'https://www.tradingview.com/script/9sOzcO32-TTF-SMC-Toolkit/'
            }]
           } } ]
      },
      {
        title: "Risk Calculator",
        children: [
          { title: "Installing MT4/5 Risk Calculator", content: { 
            type: 'video', 
            id: 'install-risk-calculator', 
            duration: '5 min',
            videoUrl: 'https://drive.google.com/file/d/1EbYtS4q6l4-u5IzIfcMk800zEuM_6Rbg/view?usp=drive_link',
            additionalContent: [{
              type: 'button',
              text: 'MT4 Version',
              link: 'https://drive.google.com/file/d/1qhCN8VeciYCrIZBb6sZMq5MlYBD0858I/view'
            },
            {
              type: 'button',
              text: 'MT5 Version',
              link: 'https://drive.google.com/file/d/1zN6ncb0MKIRXs7NTUPrxbDIpVO7NioXu/view'
            },
            {
              type: 'button',
              text: 'Mac Install Instructions',
              link: 'https://docs.google.com/document/d/101vldVp91oaILF63FW-ij1wtdkryzoy06SDuoA9eFYg/edit?tab=t.0'
            }]
           } },
          { title: "Entering A Trade In MT4", content: { type: 'video', id: 'trade-mt4', duration: '12 min', videoUrl: 'https://drive.google.com/file/d/1e6W_OejbTPFd3VU6El6-wphH7jcL2Sv2/view?usp=drive_link' } },
          { title: "Entering A Trade In MT5", content: { type: 'video', id: 'trade-mt5', duration: '11 min', videoUrl:'https://drive.google.com/file/d/1rdzdlmN_lXgbe1HelHwwXtbGJFRZD5e0/view?usp=drive_link' } },
          { title: "Risk Calculator Functions MT4", content: { type: 'video', id: 'risk-functions-mt4', duration: '9 min', videoUrl:'https://drive.google.com/file/d/1iJOYfsfHk2dnLxBFFKOr1IWZNamrORRF/view?usp=drive_link' } },
          { title: "Risk Calculator Functions MT5", content: { type: 'video', id: 'risk-functions-mt5', duration: '7 min', videoUrl:'https://drive.google.com/file/d/14zEUcl7gj7676ByYaVMFJjGSQUryQhCP/view?usp=drive_link' } },
          { title: "Process of Solving Issues MT4 & MT5", content: { type: 'video', id: 'solve-issues-mt4-mt5', duration: '4 min', videoUrl:'https://drive.google.com/file/d/1LjHCCh-IM8bqFjjKwKCOWruYb5yHmFzN/view?usp=drive_link' } }
        ]
      }
    ]
  }
];