import React from 'react';
import './MainContent.css';

const MainContent = ({ content, isCompleted, onComplete, isFavorite, onToggleFavorite }) => {
  if (!content) {
    console.log('No content provided to MainContent');
    return <div className="placeholder">Select a video or quiz to begin</div>;
  }

  const renderVideo = () => {
    if (content.videoUrl && content.videoUrl.includes('drive.google.com')) {
      const fileId = content.videoUrl.match(/[-\w]{25,}/);
      if (fileId) {
        const embedUrl = `https://drive.google.com/file/d/${fileId[0]}/preview`;
        const driveUrl = `https://drive.google.com/file/d/${fileId[0]}/view`;
        
        return (
          <>
            <div className="video-container">
              <iframe
                src={embedUrl}
                allow="autoplay; fullscreen"
                allowFullScreen
                title={content.title}
              ></iframe>
            </div>
            <button 
              onClick={() => window.open(driveUrl, '_blank')}
              className="open-in-drive-button"
            >
              📱 Open in Drive App for FullScreen
            </button>
          </>
        );
      }
    }
    return <p>Error: Invalid video URL</p>;
  };

  const renderTextContent = () => {
    return (
      <div className="text-content">
        <p>This is a text content item for: {content.id}</p>
        <p>The actual content would be loaded here based on the content ID.</p>
      </div>
    );
  };

  const renderAdditionalContent = () => {
    if (content.additionalContent && Array.isArray(content.additionalContent)) {
      return (
        <div className="additional-content">
          {content.additionalContent.map((item, index) => {
            if (item.type === 'button') {
              return (
                <a 
                  key={index}
                  href={item.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="content-button"
                >
                  {item.text}
                </a>
              );
            }
            return null;
          })}
        </div>
      );
    }
    return null;
  };

  const renderAdditionalLinks = () => {
    if (content.additionalLinks && content.additionalLinks.length > 0) {
      return (
        <div className="additional-links">
          <h3>Additional Resources</h3>
          {content.additionalLinks.map((link, index) => (
            <a 
              key={index}
              href={link.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="content-button"
            >
              {link.text}
            </a>
          ))}
        </div>
      );
    }
    return null;
  };

  const handleToggleComplete = () => {
    onComplete(content.id, content.type);
  };

  const handleToggleFavorite = () => {
    onToggleFavorite(content.id);
  };

  return (
    <div className="main-content">
      <h1 className="content-title">
        <span className="highlight">{content.title ? content.title.toUpperCase() : 'No Title'}</span>
      </h1>
      {content.type === 'video' && (
        <>
          {renderVideo()}
          <div className="lesson-controls">
            <button 
              type="button"
              className={`completion-button ${isCompleted ? 'completed' : ''}`}
              onClick={handleToggleComplete}
            >
              {isCompleted ? '✓ COMPLETED' : 'MARK AS COMPLETED'}
            </button>
            <button 
              type="button"
              className={`favorite-button ${isFavorite ? 'favorited' : ''}`}
              onClick={handleToggleFavorite}
            >
              {isFavorite ? '♥ FAVORITED' : '♥ ADD TO FAVORITES'}
            </button>
          </div>
          <div className="video-controls">
            {/* Video controls will go here */}
          </div>
          {renderAdditionalContent()}
          {renderAdditionalLinks()}
          {content.downloadable && content.downloadableUrl && (
            <>
              <h2>Downloadable Content</h2>
              <button
                onClick={() => window.open(content.downloadableUrl, '_blank')}
                className="download-link"
              >
                Download {content.downloadable}
              </button>
            </>
          )}
        </>
      )}
      {content.type === 'text' && (
        <>
          {renderTextContent()}
          <div className="lesson-controls">
            <button 
              type="button"
              className={`completion-button ${isCompleted ? 'completed' : ''}`}
              onClick={handleToggleComplete}
            >
              {isCompleted ? '✓ COMPLETED' : 'MARK AS COMPLETED'}
            </button>
            <button 
              type="button"
              className={`favorite-button ${isFavorite ? 'favorited' : ''}`}
              onClick={handleToggleFavorite}
            >
              {isFavorite ? '♥ FAVORITED' : '♥ ADD TO FAVORITES'}
            </button>
          </div>
          {renderAdditionalContent()}
          {renderAdditionalLinks()}
        </>
      )}
    </div>
  );
};

export default MainContent;