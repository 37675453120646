import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import Header from './components/Header';
import FilloutQuiz from './components/FilloutQuiz';
import Favorites from './components/Favorites';
import { sidebarData } from './data/sidebarData';
import { api } from './apiUtils';
import './App.css';

const PREDEFINED_USERS = ['yassin', 'rafik', 'faiz'];

// Helper to flatten sidebar data for easy content access
const getFlattenedContent = (data) => {
  let flattened = {};
  const flatten = (items) => {
    items.forEach(item => {
      if (item.content) {
        flattened[item.content.id] = {
          ...item.content,
          title: item.title
        };
      }
      if (item.children) {
        flatten(item.children);
      }
    });
  };
  flatten(data);
  return flattened;
};

// Helper to count total completable items (videos only)
const getTotalCompletableItems = (data) => {
  let count = 0;
  const traverse = (items) => {
    items.forEach(item => {
      if (item.content && item.content.type === 'video') {
        count++;
      }
      if (item.children) {
        traverse(item.children);
      }
    });
  };
  traverse(data);
  return count;
};

const App = () => {
  // Get flattened content and total items once
  const content = getFlattenedContent(sidebarData);
  const totalCompletableItems = getTotalCompletableItems(sidebarData);

  // Basic state
  const [currentView, setCurrentView] = useState('main');
  const [currentContent, setCurrentContent] = useState('ttf-road-map');
  const [user, setUser] = useState(() => localStorage.getItem('currentUser') || null);
  
  // User progress state (videos only)
  const [userProgress, setUserProgress] = useState({});
  const [favorites, setFavorites] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Load user data on initial mount and when user changes
  useEffect(() => {
    const loadUserData = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const userData = await api.getUserData(user);
        setUserProgress(prev => ({
          ...prev,
          [user]: { videos: userData.videos || [] }
        }));
        setFavorites(prev => ({
          ...prev,
          [user]: userData.favorites || []
        }));
      } catch (error) {
        console.error('Failed to load user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUserData();
  }, [user]);

  // Save user data whenever it changes
  useEffect(() => {
    const saveUserData = async () => {
      if (!user || isLoading) return;

      try {
        await api.updateUserData(user, {
          videos: userProgress[user]?.videos || [],
          favorites: favorites[user] || []
        });
      } catch (error) {
        console.error('Failed to save user data:', error);
      }
    };

    saveUserData();
  }, [user, userProgress, favorites, isLoading]);

  // Calculate current user's progress
  const calculateProgress = useCallback(() => {
    if (!user || !userProgress[user]) return 0;
    
    const completed = userProgress[user];
    const totalCompleted = completed.videos.length || 0;
    return (totalCompleted / totalCompletableItems) * 100;
  }, [user, userProgress, totalCompletableItems]);

  // Handle completing content
  const handleCompleteContent = useCallback(async (contentId) => {
    if (!user) return;
    
    setUserProgress(prev => {
      const currentItems = [...(prev[user]?.videos || [])];
      const itemIndex = currentItems.indexOf(contentId);
      
      if (itemIndex !== -1) {
        currentItems.splice(itemIndex, 1);
      } else {
        currentItems.push(contentId);
      }
      
      return {
        ...prev,
        [user]: { videos: currentItems }
      };
    });
  }, [user]);

  // Handle favoriting content
  const handleToggleFavorite = useCallback(async (contentId) => {
    if (!user) return;
    
    setFavorites(prev => {
      const userFavorites = [...(prev[user] || [])];
      const itemIndex = userFavorites.indexOf(contentId);
      
      if (itemIndex !== -1) {
        userFavorites.splice(itemIndex, 1);
      } else {
        userFavorites.push(contentId);
      }
      
      return {
        ...prev,
        [user]: userFavorites
      };
    });
  }, [user]);

  // Handle login/logout
  const handleLogin = async (username) => {
    const lowercaseUsername = username.toLowerCase();
    if (PREDEFINED_USERS.includes(lowercaseUsername)) {
      setUser(lowercaseUsername);
      localStorage.setItem('currentUser', lowercaseUsername);
    } else {
      alert('Invalid username. Please try again.');
    }
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('currentUser');
    setCurrentView('main');
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  // Login screen
  if (!user) {
    return (
      <div className="login-container">
        <h1>Login</h1>
        <input
          type="text"
          placeholder="Enter your name"
          id="loginInput"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleLogin(e.target.value);
            }
          }}
        />
        <button 
          className="login-button" 
          onClick={() => {
            const input = document.getElementById('loginInput');
            handleLogin(input.value);
          }}
        >
          Login
        </button>
      </div>
    );
  }

  // Main app
  return (
    <div className="app">
      <Header 
        username={user} 
        progress={calculateProgress()}
        onLogout={handleLogout}
        currentView={currentView}
        onNavigate={setCurrentView}
      />
      {currentView === 'favorites' ? (
        <Favorites 
          favorites={new Set(favorites[user] || [])}
          content={content}
          onSelectContent={(id) => {
            setCurrentContent(id);
            setCurrentView('main');
          }}
        />
      ) : (
        <div className="app-content">
          <Sidebar
            onSelectContent={setCurrentContent}
            currentContent={currentContent}
            completedContent={userProgress[user] || { videos: [] }}
            sidebarData={sidebarData}
          />
          {renderContent()}
        </div>
      )}
    </div>
  );

  // Content rendering helper
  function renderContent() {
    const currentItem = content[currentContent];
    if (!currentItem) {
      return <div className="content-not-found">Content not found</div>;
    }

    switch (currentItem.type) {
      case 'video':
        const isVideoCompleted = userProgress[user]?.videos.includes(currentContent) || false;
        return (
          <MainContent
            key={currentContent}
            content={currentItem}
            isCompleted={isVideoCompleted}
            onComplete={() => handleCompleteContent(currentContent)}
            isFavorite={favorites[user]?.includes(currentContent) || false}
            onToggleFavorite={() => handleToggleFavorite(currentContent)}
          />
        );

      case 'quiz':
        return <FilloutQuiz quizUrl={currentItem.quizUrl} />;

      case 'text':
        return (
          <div className="text-content">
            <h2>{currentItem.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: currentItem.content || "Content not available yet." }} />
            {currentItem.additionalContent?.map((item, index) => (
              <a 
                key={index}
                href={item.link} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="content-button"
              >
                {item.text}
              </a>
            ))}
          </div>
        );

      default:
        return <div className="unsupported-content">Content type not supported</div>;
    }
  }
};

export default App;