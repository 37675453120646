import React, { useState } from 'react';
import './Sidebar.css';
import { sidebarData } from '../data/sidebarData';

const Sidebar = ({ onSelectContent, currentContent, completedContent = {} }) => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (sectionPath) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionPath]: !prev[sectionPath]
    }));
  };

  const isCompleted = (item) => {
    if (!item.content) return false;
    
    // Ensure completedContent has the required arrays
    const videos = completedContent.videos || [];
    const quizzes = completedContent.quizzes || [];
    
    switch (item.content.type) {
      case 'video':
        return videos.includes(item.content.id);
      case 'quiz':
        return quizzes.includes(item.content.id);
      default:
        return false;
    }
  };

  const renderItems = (items, parentPath = '') => {
    return (
      <ul className="nested-list">
        {items.map((item, index) => {
          const currentPath = parentPath ? `${parentPath}-${index}` : `${index}`;
          const isActive = item.content && item.content.id === currentContent;
          const completed = isCompleted(item);
          
          return (
            <li key={currentPath}>
              {item.children ? (
                <>
                  <button 
                    onClick={() => toggleSection(currentPath)} 
                    className={`sidebar-title ${isActive ? 'active' : ''} ${completed ? 'completed' : ''}`}
                  >
                    <span className="link-content">
                      {item.title}
                      <span className="section-icon">
                        {expandedSections[currentPath] ? '▼' : '▶'}
                      </span>
                    </span>
                    {completed && <span className="completion-check">✓</span>}
                  </button>
                  {expandedSections[currentPath] && renderItems(item.children, currentPath)}
                </>
              ) : (
                <button
                  onClick={() => onSelectContent(item.content.id)}
                  className={`sidebar-link ${isActive ? 'active' : ''} ${completed ? 'completed' : ''}`}
                >
                  <span className="link-content">
                    {item.title}
                    {completed && <span className="completion-check">✓</span>}
                  </span>
                </button>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <aside className="sidebar">
      <h2>Day Trading Education</h2>
      {renderItems(sidebarData)}
    </aside>
  );
};

export default Sidebar;