import React from 'react';
import './Header.css';

const Header = ({ 
  username = "yassin moumni", 
  progress = 0, 
  onLogout,
  currentView,
  onNavigate
}) => {
  const safeProgress = Math.min(Math.max(0, progress), 100);
  
  return (
    <header className="header">
      <div className="logo-container">
        <span className="logo">FOREX SHIZZ</span>
        <span className="sub-logo">IN 2035 ...</span>
      </div>
      <nav>
        <button 
          onClick={() => onNavigate('main')}
          className={`nav-button ${currentView === 'main' ? 'active' : ''}`}
        >
          Home
        </button>
        <button 
          onClick={() => onNavigate('favorites')}
          className={`nav-button ${currentView === 'favorites' ? 'active' : ''}`}
        >
          Favorites
        </button>
      </nav>
      <div className="user-section">
        <div className="user-info">
          <span className="username">{username}</span>
          <div className="progress-container">
            <div className="progress-bar">
              <div 
                className="progress" 
                style={{width: `${safeProgress}%`}}
                title={`${Math.round(safeProgress)}% complete`}
              ></div>
            </div>
            <span className="progress-text">{Math.round(safeProgress)}%</span>
          </div>
        </div>
        <button onClick={onLogout} className="logout-button">Logout</button>
      </div>
    </header>
  );
};

export default Header;