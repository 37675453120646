import React from 'react';
import './Favorites.css';

const Favorites = ({ favorites, content, onSelectContent }) => {
  // If no favorites, show a message
  if (!favorites.size) {
    return (
      <div className="favorites-empty">
        <h2>No Favorites Yet</h2>
        <p>Click the ♥ button on any video or lesson to add it to your favorites.</p>
      </div>
    );
  }

  // Create an array of favorited content items
  const favoritedItems = Array.from(favorites).map(id => {
    const item = content[id];
    return {
      id,
      title: item.title,
      type: item.type
    };
  });

  return (
    <div className="favorites-container">
      <h2>My Favorites</h2>
      <ul className="favorites-list">
        {favoritedItems.map(item => (
          <li key={item.id} className="favorite-item">
            <button
              onClick={() => onSelectContent(item.id)}
              className="favorite-link"
            >
              <span className="favorite-title">{item.title}</span>
              <span className="favorite-type">{item.type}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Favorites;